var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-button-toolbar",
                                                              [
                                                                _c(
                                                                  "b-button-group",
                                                                  [
                                                                    _c(
                                                                      "base-input",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm
                                                                            .beForm[
                                                                            _vm
                                                                              .rep
                                                                          ]
                                                                            .insurance_policy_id
                                                                            .label,
                                                                          label:
                                                                            _vm
                                                                              .beForm[
                                                                              _vm
                                                                                .rep
                                                                            ]
                                                                              .insurance_policy_id
                                                                              .label,
                                                                          rules:
                                                                            _vm.getRules(
                                                                              "insurance_policy_id"
                                                                            ),
                                                                          readonly: true,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .inpt_label,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                "inpt_label",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.inpt_label",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Ricerca Veloce",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.openQuickSearchPolicy,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "search",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _vm.insurance_policy_data
                                                          ? _c("span", {
                                                              staticClass:
                                                                "info",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.toInfoData(
                                                                      _vm.insurance_policy_data,
                                                                      "insurance_policy",
                                                                      0
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "quick-search-policy-modal",
                                                      {
                                                        ref: "quickSearchPolicy",
                                                        on: {
                                                          input:
                                                            _vm.handlePolicyModalInput,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "effective_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].effective_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .effective_at
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "effective_at"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .effective_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "effective_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].effective_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "delay_days",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].delay_days.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .delay_days.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .delay_days
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "delay_days"
                                                            ),
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .delay_days,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "delay_days",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].delay_days",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].broker_id.label,
                                                          vid: "broker_id",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .broker_id.label,
                                                          options:
                                                            _vm.broker_options,
                                                          rules:
                                                            _vm.getRules(
                                                              "broker_id"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .broker_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "broker_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].broker_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "mt-2" },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header: "Premio",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onClickSwitch()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          name: "check-button",
                                                          switch: "",
                                                          disabled: true,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.onChangeSwitch()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.isBreakdown,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.isBreakdown =
                                                              $$v
                                                          },
                                                          expression:
                                                            "isBreakdown",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.isBreakdown
                                                                ? "Scorpori Premio"
                                                                : "Premio"
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                !_vm.isBreakdown
                                                  ? _c(
                                                      "b-card-text",
                                                      [
                                                        _c("b-row", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-currency",
                                                                {
                                                                  attrs: {
                                                                    name: _vm
                                                                      .beForm[
                                                                      _vm.rep
                                                                    ].net.label,
                                                                    vid: "net",
                                                                    label:
                                                                      _vm
                                                                        .beForm[
                                                                        _vm.rep
                                                                      ].net
                                                                        .label,
                                                                    options: {
                                                                      currency:
                                                                        "EUR",
                                                                      locale:
                                                                        "it-IT",
                                                                      precision: 2,
                                                                    },
                                                                    rules:
                                                                      _vm.getRules(
                                                                        "net"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form[
                                                                        _vm.rep
                                                                      ].net,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form[
                                                                            _vm
                                                                              .rep
                                                                          ],
                                                                          "net",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form[rep].net",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-currency",
                                                                {
                                                                  attrs: {
                                                                    name: _vm
                                                                      .beForm[
                                                                      _vm.rep
                                                                    ].tax.label,
                                                                    vid: "tax_premium",
                                                                    label:
                                                                      _vm
                                                                        .beForm[
                                                                        _vm.rep
                                                                      ].tax
                                                                        .label,
                                                                    options: {
                                                                      currency:
                                                                        "EUR",
                                                                      locale:
                                                                        "it-IT",
                                                                      precision: 2,
                                                                    },
                                                                    rules:
                                                                      _vm.getRules(
                                                                        "tax"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form[
                                                                        _vm.rep
                                                                      ].tax,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form[
                                                                            _vm
                                                                              .rep
                                                                          ],
                                                                          "tax",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form[rep].tax",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c("base-input", {
                                                                attrs: {
                                                                  name: _vm
                                                                    .beForm[
                                                                    _vm.rep
                                                                  ].gross.label,
                                                                  vid: "gross",
                                                                  label:
                                                                    _vm.beForm[
                                                                      _vm.rep
                                                                    ].gross
                                                                      .label,
                                                                  readonly: true,
                                                                  custom_type:
                                                                    "currency",
                                                                  rules:
                                                                    _vm.getRules(
                                                                      "gross"
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.grossPremiumSum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.grossPremiumSum =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "grossPremiumSum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isBreakdown
                                                  ? _c("b-card-text", [
                                                      _vm.breakdowns.length
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-overlay",
                                                                {
                                                                  attrs: {
                                                                    center: "",
                                                                    show: _vm.isLoadingBreakdown,
                                                                    rounded:
                                                                      "sm",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "overlay",
                                                                          fn: function () {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          name: "loading",
                                                                                          width:
                                                                                            "35",
                                                                                          height:
                                                                                            "35",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "p",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          id: "cancel-label",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Operazione in corso..."
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-table",
                                                                    {
                                                                      ref: "breakdowns",
                                                                      attrs: {
                                                                        hover:
                                                                          "",
                                                                        items:
                                                                          _vm.breakdowns,
                                                                        fields:
                                                                          _vm.fields,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "cell(net)",
                                                                              fn: function (
                                                                                row
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-group col-md-7",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name: _vm
                                                                                                .beForm[
                                                                                                _vm
                                                                                                  .rep
                                                                                              ]
                                                                                                .net
                                                                                                .label,
                                                                                              vid: `net-${row.item.id}`,
                                                                                              label:
                                                                                                _vm
                                                                                                  .beForm[
                                                                                                  _vm
                                                                                                    .rep
                                                                                                ]
                                                                                                  .net
                                                                                                  .label,
                                                                                              options:
                                                                                                {
                                                                                                  currency:
                                                                                                    "EUR",
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              readonly:
                                                                                                row
                                                                                                  .item
                                                                                                  .net
                                                                                                  .is_net ===
                                                                                                "Y"
                                                                                                  ? false
                                                                                                  : true,
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                row
                                                                                                  .item
                                                                                                  .net
                                                                                                  .value,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    row
                                                                                                      .item
                                                                                                      .net,
                                                                                                    "value",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "row.item.net.value",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "cell(tax)",
                                                                              fn: function (
                                                                                row
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-group col-md-7",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name: "Tasse",
                                                                                              vid: `tax-${row.item.id}`,
                                                                                              label:
                                                                                                "Tasse",
                                                                                              options:
                                                                                                {
                                                                                                  currency:
                                                                                                    "EUR",
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              readonly:
                                                                                                row
                                                                                                  .item
                                                                                                  .tax
                                                                                                  .is_tax ===
                                                                                                "Y"
                                                                                                  ? false
                                                                                                  : true,
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                row
                                                                                                  .item
                                                                                                  .tax
                                                                                                  .value,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    row
                                                                                                      .item
                                                                                                      .tax,
                                                                                                    "value",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "row.item.tax.value",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "cell(grossrow)",
                                                                              fn: function (
                                                                                row
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-group col-md-7",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-input",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name: "grossrow",
                                                                                              vid: `grossrow-${row.item.id}`,
                                                                                              label:
                                                                                                "Lordo Riga",
                                                                                              custom_type:
                                                                                                "currency",
                                                                                              value:
                                                                                                _vm.grossRowBookEntry(
                                                                                                  row
                                                                                                    .item
                                                                                                    .tax
                                                                                                    .value,
                                                                                                  row
                                                                                                    .item
                                                                                                    .net
                                                                                                    .value
                                                                                                ),
                                                                                              readonly: true,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot: "bottom-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "Totale"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-group col-md-7",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-label",
                                                                                    {
                                                                                      staticClass:
                                                                                        "net-sum-label",
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            _vm.netSum,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-group col-md-7",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-label",
                                                                                    {
                                                                                      staticClass:
                                                                                        "tax-sum-label",
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            _vm.taxSum,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-group col-md-7",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "base-label",
                                                                                    {
                                                                                      staticClass:
                                                                                        "gross-sum-label",
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            _vm.grossSum,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.agencyHasFee
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Diritti",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "fee_net",
                                                              vid: "fee_net",
                                                              label: "Netto",
                                                              readonly:
                                                                !_vm.insurerHasFee,
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].fee_net,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "fee_net",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].fee_net",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "fee_tax",
                                                              vid: "fee_tax",
                                                              label: "Tasse",
                                                              readonly:
                                                                !_vm.insurerHasFee,
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].fee_tax,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "fee_tax",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].fee_tax",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "fee_gross",
                                                              vid: "fee_gross",
                                                              label: "Lordo",
                                                              readonly: "",
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.grossSumFee,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.grossSumFee =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "grossSumFee",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("custom-inputs", {
                                                      attrs: {
                                                        customInputs:
                                                          _vm.customInputs[
                                                            _vm.rep
                                                          ],
                                                        beForm:
                                                          _vm.beForm[_vm.rep],
                                                        beRules:
                                                          _vm.beRules[_vm.rep],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            _vm.rep,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form[rep]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.resetForm()
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3661675263
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }