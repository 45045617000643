var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-button-toolbar",
                                                              [
                                                                _c(
                                                                  "b-button-group",
                                                                  [
                                                                    _c(
                                                                      "base-input",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm
                                                                            .beForm[
                                                                            _vm
                                                                              .rep
                                                                          ]
                                                                            .insurance_policy_id
                                                                            .label,
                                                                          label:
                                                                            _vm
                                                                              .beForm[
                                                                              _vm
                                                                                .rep
                                                                            ]
                                                                              .insurance_policy_id
                                                                              .label,
                                                                          rules:
                                                                            _vm.getRules(
                                                                              "insurance_policy_id"
                                                                            ),
                                                                          readonly: true,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .inpt_label,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                "inpt_label",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.inpt_label",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Ricerca Veloce",
                                                                          disabled: true,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.openQuickSearchPolicy,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "search",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _vm.insurance_policy_data
                                                          ? _c("span", {
                                                              staticClass:
                                                                "info",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.toInfoData(
                                                                      _vm.insurance_policy_data,
                                                                      "insurance_policy",
                                                                      0
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "quick-search-policy-modal",
                                                      {
                                                        ref: "quickSearchPolicy",
                                                        on: {
                                                          input:
                                                            _vm.handlePolicyModalInput,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].code.label,
                                                          vid: "code",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .code.label,
                                                          options:
                                                            _vm.code_options,
                                                          rules:
                                                            _vm.getRules(
                                                              "code"
                                                            ),
                                                          disabled: [
                                                            0, 1, 2, 3, 4, 5, 6,
                                                          ].includes(
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .value
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].code",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].status_ancillary
                                                            .label,
                                                          vid: "status_ancillary",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "status_ancillary"
                                                            ),
                                                          disabled:
                                                            _vm.disabled ||
                                                            [4, 5, 6].includes(
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ].status_ancillary
                                                                .value
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .status_ancillary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "status_ancillary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].status_ancillary",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "effective_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].effective_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .effective_at
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "effective_at"
                                                            ),
                                                          readonly: [
                                                            0, 1, 2, 3, 4, 5, 6,
                                                          ].includes(
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .value
                                                          ),
                                                          disabled: [
                                                            0, 1, 2, 3, 4, 5, 6,
                                                          ].includes(
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .value
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .effective_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "effective_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].effective_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "delay_days",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].delay_days.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .delay_days.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .delay_days
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "delay_days"
                                                            ),
                                                          disabled:
                                                            [
                                                              0, 1, 2, 3, 4, 5,
                                                              6,
                                                            ].includes(
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ].status_ancillary
                                                                .value
                                                            ) ||
                                                            _vm.beForm[_vm.rep]
                                                              .code.value ===
                                                              "A"
                                                              ? true
                                                              : false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .delay_days,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "delay_days",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].delay_days",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].broker_id.label,
                                                          vid: "broker_id",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .broker_id.label,
                                                          options: _vm.salesmen,
                                                          rules:
                                                            _vm.getRules(
                                                              "broker_id"
                                                            ),
                                                          disabled: [
                                                            0, 1, 2, 3, 4, 5, 6,
                                                          ].includes(
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .value
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .broker_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "broker_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].broker_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              header: "Premio",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].net.label,
                                                          vid: "net",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .net.label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                          rules:
                                                            _vm.getRules("net"),
                                                          disabled: [
                                                            1, 2, 3, 4, 5, 6,
                                                          ].includes(
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .value
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .net,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "net",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].net",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].tax.label,
                                                          vid: "tax",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .tax.label,
                                                          rules:
                                                            _vm.getRules("tax"),
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                          disabled: [
                                                            1, 2, 3, 4, 5, 6,
                                                          ].includes(
                                                            _vm.beForm[_vm.rep]
                                                              .status_ancillary
                                                              .value
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .tax,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "tax",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].tax",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].gross.label,
                                                          vid: "gross",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .gross.label,
                                                          readonly: true,
                                                          custom_type:
                                                            "currency",
                                                          rules:
                                                            _vm.getRules(
                                                              "gross"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.grossPremiumSum,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.grossPremiumSum =
                                                              $$v
                                                          },
                                                          expression:
                                                            "grossPremiumSum",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.agencyHasFee
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  header: "Diritti",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "fee_net",
                                                              vid: "fee_net",
                                                              label: "Netto",
                                                              readonly:
                                                                [
                                                                  1, 2, 3, 4, 5,
                                                                  6,
                                                                ].includes(
                                                                  _vm.beForm[
                                                                    _vm.rep
                                                                  ]
                                                                    .status_ancillary
                                                                    .value
                                                                ) ||
                                                                !_vm.insurerHasFee,
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].fee_net,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "fee_net",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].fee_net",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "fee_tax",
                                                              vid: "fee_tax",
                                                              label: "Tasse",
                                                              readonly:
                                                                [
                                                                  1, 2, 3, 4, 5,
                                                                  6,
                                                                ].includes(
                                                                  _vm.beForm[
                                                                    _vm.rep
                                                                  ]
                                                                    .status_ancillary
                                                                    .value
                                                                ) ||
                                                                !_vm.insurerHasFee,
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].fee_tax,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "fee_tax",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].fee_tax",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "fee_gross",
                                                              vid: "fee_gross",
                                                              label: "Lordo",
                                                              readonly: "",
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.grossSumFee,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.grossSumFee =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "grossSumFee",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isDelegaNostra &&
                                        _vm.isPorQ &&
                                        !_vm.isDeposited
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  header: "Coass",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-table", {
                                                      attrs: {
                                                        striped: "",
                                                        hover: "",
                                                        items:
                                                          _vm.insurerParticipants,
                                                        fields:
                                                          _vm.insurerParticipantsFields,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "cell(compagnia_delegata)",
                                                            fn: function (row) {
                                                              return [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      row.item
                                                                        .code_importer
                                                                    ) +
                                                                    " -\n                    " +
                                                                    _vm._s(
                                                                      row.item
                                                                        .title_importer
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(agenzia_delegata)",
                                                            fn: function (row) {
                                                              return [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      row.item
                                                                        .code
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(descrizione)",
                                                            fn: function (row) {
                                                              return [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      row.item
                                                                        .title
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(participant_prov_purchase)",
                                                            fn: function (row) {
                                                              return [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm.toLocaleCurrency(
                                                                        row.item
                                                                          .pivot
                                                                          .participant_prov_purchase
                                                                      )
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(participant_prov_take)",
                                                            fn: function (row) {
                                                              return [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm.toLocaleCurrency(
                                                                        row.item
                                                                          .pivot
                                                                          .participant_prov_take
                                                                      )
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(net)",
                                                            fn: function (row) {
                                                              return [
                                                                _c(
                                                                  "base-currency",
                                                                  {
                                                                    attrs: {
                                                                      vid: "net",
                                                                      name: "net",
                                                                      label:
                                                                        "Netto",
                                                                      options: {
                                                                        locale:
                                                                          "it-IT",
                                                                        currency:
                                                                          "EUR",
                                                                        precision: 2,
                                                                      },
                                                                      readonly:
                                                                        _vm.isImported ||
                                                                        !_vm.isDaIncassare,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .insurerParticipantsForm[
                                                                          row
                                                                            .item
                                                                            .id
                                                                        ].net,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .insurerParticipantsForm[
                                                                              row
                                                                                .item
                                                                                .id
                                                                            ],
                                                                            "net",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "insurerParticipantsForm[row.item.id].net",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(tax)",
                                                            fn: function (row) {
                                                              return [
                                                                _c(
                                                                  "base-currency",
                                                                  {
                                                                    attrs: {
                                                                      vid: "tax",
                                                                      name: "tax",
                                                                      label:
                                                                        "Tasse",
                                                                      options: {
                                                                        locale:
                                                                          "it-IT",
                                                                        currency:
                                                                          "EUR",
                                                                        precision: 2,
                                                                      },
                                                                      readonly:
                                                                        _vm.isImported ||
                                                                        !_vm.isDaIncassare,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .insurerParticipantsForm[
                                                                          row
                                                                            .item
                                                                            .id
                                                                        ].tax,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .insurerParticipantsForm[
                                                                              row
                                                                                .item
                                                                                .id
                                                                            ],
                                                                            "tax",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "insurerParticipantsForm[row.item.id].tax",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(gross)",
                                                            fn: function (row) {
                                                              return [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .insurerParticipantsForm[
                                                                        row.item
                                                                          .id
                                                                      ].tax &&
                                                                        _vm
                                                                          .insurerParticipantsForm[
                                                                          row
                                                                            .item
                                                                            .id
                                                                        ].net
                                                                        ? _vm.toLocaleCurrency(
                                                                            _vm.insPrtcGrss(
                                                                              row
                                                                                .item
                                                                                .id
                                                                            )
                                                                          )
                                                                        : _vm.toLocaleCurrency(
                                                                            _vm
                                                                              .insurerParticipantsForm[
                                                                              row
                                                                                .item
                                                                                .id
                                                                            ]
                                                                              .gross
                                                                          )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("custom-inputs", {
                                                      attrs: {
                                                        customInputs:
                                                          _vm.customInputs[
                                                            _vm.rep
                                                          ],
                                                        beForm:
                                                          _vm.beForm[_vm.rep],
                                                        beRules:
                                                          _vm.beRules[_vm.rep],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            _vm.rep,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form[rep]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3324871301
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }